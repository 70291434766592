body {
	background-color: $color_verlauf_hell;
	background: radial-gradient(at 20% 35%,rgba(247, 247, 247, 0.55),rgba(193, 193, 193, 0.4));
	// background: linear-gradient(to bottom right, $color_verlauf_dunkel, $color_verlauf_hell);
	background-repeat: no-repeat;
  background-attachment: fixed;
}

.area-content.minheight {
	min-height: 100vh;
}

.area-foot {
	padding-top: 15rem;
}
