.fey_header.ap-header {
  .mainheader  {
    .bis-logogroup {
      display: none;
    }
  }
  .head-navbar {
    @media (max-width: $screen-mobile-max) {
      background: #fff
    }
  }
  @media (min-width: $screen-desktop-min) {
    .nav {
      border-bottom: 1px solid #585F6A;
      & > li{
        & > a {
          &:hover, &:focus {
            border-bottom-style: solid;
            color: $main-theme;
          }
        }
        &.open,
        &.active {
          & > a {
            &,
            &:hover, &:focus {
              border-bottom-style: solid;
              color: $main-theme;
           }
         }
       }
      }
      .dropdown-menu {
        background-color: $nav-bg;
        .form-control {
          background-color: $color_verlauf_hell;
        }
        .btn {
          color: $nav-text;
        }
      }
    }
  }
  &::after {
    content: '';
    display: block;
  	position: fixed;
  	z-index: 100;
  	height: 40%; //65%
  	max-height: 765px;
  	width: 40%;
  	max-width: 800px;
  	background: url('../clarafey/img/logo_wortbild_anschnitt_datum.svg');
  	background-size: 800px 765px;
  	background-size: contain;
  	background-repeat: no-repeat;
  	background-position: right bottom;
  	margin: 0;
  	padding: 0;
  	right: 0;
  	bottom: 0;
  }
}
