@media (min-width: $screen-desktop-min) {
	#fey_portrait {
		display: block;
		position: fixed;
		z-index: -1;
		height: 100%;
		width: 100%;
		margin: 0;
		padding: 0;
		left: 0;
		bottom: 0;
		padding-top: 100px;
		padding-right: 25%;
		background: radial-gradient(at 20% 35%, rgba(239, 239, 239, 0.55), rgba(93, 93, 93, 0.4)); // $color_verlauf_dunkel, $color_verlauf_hell
		.inside {
			display: block;
			height: 100%;
			width: 100%;
			margin-top: 5%;
			background: url("../clarafey/img/portrait.png");
			background-size: contain;
			background-repeat: no-repeat;
			background-position: bottom left;
		}
	}
}
